<template>
  <div class="deviceNav">
    <router-link class="deviceNav_linkItem" to="/linweishi">林卫视</router-link>
    <router-link class="deviceNav_linkItem" to="/command-center">智慧大屏</router-link>
    <router-link class="deviceNav_linkItem" to="/pancam">全景监测站</router-link>
    <router-link class="deviceNav_linkItem" to="/ir-camera2"><span>（联网款）</span><span>红外相机</span></router-link>
    <router-link class="deviceNav_linkItem" to="/ir-camera"><span>（标准版）</span><span>红外相机</span></router-link>
  </div>
</template>
<script lang="ts" setup>

</script>
<style lang="scss">
.deviceNav {
  display: flex;
  justify-content: space-between;
  height: 50px;
  margin-bottom: 12px;
  border-bottom: 1px solid #e6e6e6;
  &_linkItem {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #909399;
    border-bottom: 2px solid rgb(255, 255, 255);
    transition: all 0.2s;
    flex-direction: column;
        justify-content: flex-end;
    padding-bottom: 8px;
  }
  &_linkItem:hover {
    color: #000;
  }

  &_linkItem.router-link-active {
    border-bottom: 2px solid #409eff;
  }
  &_linkItem:nth-child(4),
  &_linkItem:nth-child(5) {
    
    flex-direction: column;
  }
}
</style>